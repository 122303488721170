import {BREAK_POINTS} from '../settings';
import {myDevice} from './device';

export default function() {
  //ハンバーガーメニュークリックと画面サイズに応じて aria 属性とクラス名の切り替え
  //メニューが開いている状態 aria-expanded: true, aria-hidden: false, .is-active 追加
  //メニューが閉じている状態 aria-expanded: false, aria-hidden: true, .is-active 削除
  let elWrapper,elBtns,elTarget;

  function init() {
    elWrapper = document.querySelector('.js-gnav-wrapper');
    elBtns = document.querySelectorAll('.js-gnav-toggle');
    elTarget = document.querySelector('.js-gnav-target');
		links = elTarget.querySelectorAll('a');
		resize();

    [].forEach.call(elBtns, el => {
      el.addEventListener('click', clickHandle)
    });

    [].forEach.call(links, el => {
      el.addEventListener('click', (e) => {
				console.log("#");
				hide();
			})
    });
  }

	function resize() {
    if(window.innerWidth > BREAK_POINTS.ltSP) {
      show();
    } else {
      hide();
    }
	}

	function hide() {
		elWrapper.setAttribute("aria-expanded", false);
		elTarget.setAttribute("aria-hidden", true);
		[].forEach.call(elBtns, el => {
			el.classList.remove('is-active');
		});
	}

	function show() {
		elWrapper.setAttribute("aria-expanded", true);
		elTarget.setAttribute("aria-hidden", false);
		[].forEach.call(elBtns, el => {
			el.classList.add('is-active');
		});
	}

  function toggleAtributes() {
    if(elTarget.getAttribute('aria-hidden') == "true") {
			show();
    } else {
			hide();
    }
  }

  function clickHandle(e) {
    e.preventDefault();
    toggleAtributes();
  }

  window.addEventListener('DOMContentLoaded', init);

  if (!myDevice.isTouchDevice) {
	  window.addEventListener('resize', resize);
	}
}
