import addARIACurrent from './module/addARIACurrent';
import {fadeToggle} from './module/fade';
import {slideToggle} from './module/slide';
import pcAddIshover from './module/pcAddIshover';
import gnavToggle from './module/gnavToggle';
// import smoothScroll from './module/smoothScroll';

(function () {

  window.addEventListener('DOMContentLoaded', function(e) {
    addARIACurrent();
    slideToggle();
    pcAddIshover();
    // smoothScroll();
		const pagrtopBtn = document.getElementById('js-pagetop');

		window.addEventListener('scroll', (e) => {
			const scTop = window.pageYOffset || document.documentElement.scrollTop;
			if(scTop > 400) {
				pagrtopBtn.classList.add('is-show');
			} else {
				pagrtopBtn.classList.remove('is-show');
			}
		})

    const unitEl = document.querySelectorAll('.js-fade-unit');
    [].forEach.call(unitEl, function (el) {
      const btn = el.querySelector('.js-fade-switch')
      const elTarget = el.querySelector('.js-fade-target');
      btn.addEventListener('click', function(e) {
        fadeToggle(e.currentTarget, elTarget);
      })
    });

		const sunitEl = document.querySelectorAll('.js-slide-unit');

		[].forEach.call(sunitEl, function (el) {
			const sbtn = el.querySelector('.js-slide-switch')
			const selTarget = el.querySelector('.js-slide-target');
			sbtn.addEventListener('click', function(e) {
				slideToggle(e.currentTarget, selTarget);
			});
		});
  });

  window.addEventListener('load', function(e) {
    // addScrollIntoView();
  });

  gnavToggle();
})();
